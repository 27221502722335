import React from "react"
import "./style.css"

const Footer = () => {
  const login = localStorage.getItem('PcUserRole');

  const tdStyle = {
    WebkitTextSizeAdjust: 'none',
    borderCollapse: 'collapse',
    fontFamily: 'Helvetica, Verdana, Arial',
    fontSize: '12px',
    color: '#7f7f7f'
  };

  const linkStyle = {
    color: '#499d68'
  };

  return (
    <footer class="footer-section">
      <div>
        <div class="footer-cta pt-5 pb-5">
          <div style={{ padding: "0px 30px" }}>
            <div class="row">
              <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                  <i class="fas fa-map-marker-alt"></i>
                  <div class="cta-text">
                    <h4>Find us</h4>
                    <span>Balaji Residency, Kiran chowk, surat</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                  <a href="tel:02613556945">
                    <i class="fas fa-phone"></i>
                    <div class="cta-text">
                      <h4>Call us</h4>
                      <span>+91 99138 56957</span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                  <a href="mailto:pccomputer.trading@gmail.com">
                    <i class="far fa-envelope-open"></i>
                    <div class="cta-text">
                      <h4>Mail us</h4>
                      <span>pccomputer.trading@gmail.com</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-content pt-5 pb-5">
          <div class="row" style={{ padding: "0px 30px" }}>
            <div class="col-xl-4 col-lg-4 mb-50">
              <div class="footer-widget">
                <div class="footer-logo justify-content--center">
                  <a href="index.html"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtN6chFgHIBXbemL1R_i9H6V8U0USPxc6bkdAbANc9sbkvgWvdWpnTk3PIOR480kowlQA&usqp=CAU" class="img-fluid" alt="logo" width={'200px'} style={{ alignContent: "center" }} /></a>
                </div>
                {/* <div class="footer-text">
                  <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                    elit,Lorem ipsum dolor sit amet.</p>
                </div> */}
                {/* <div class="footer-social-icon">
                    <span>Follow us</span>
                    <a href="#"><i class="fab fa-facebook-f facebook-bg"></i></a>
                    <a href="#"><i class="fab fa-twitter twitter-bg"></i></a>
                    <a href="#"><i class="fab fa-google-plus-g google-bg"></i></a>
                  </div> */}
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li><a href="/">Home</a></li>
                  {/* <li><a href={login === null ? "/login" : "/like"}>Like</a></li> */}
                  {/* <li><a href="/branch">Contact us</a></li> */}
                  {/* <li><a href={login === null ? "/login" : "/cart"}>Cart</a></li> */}
                  {/* <li><a href="/branch">Our Branches</a></li> */}
                  {/* <li><a href={login === null ? "/login" : "/profile"}>Profile</a></li> */}
                  <li><a href="#"></a></li>
                  {/* <li><a href={login === null ? "/login" : "/profile"}>Order History</a></li> */}
                </ul>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>Download Our App</h3>
                </div>
                <td style={tdStyle}>
                  <a style={linkStyle} href="https://play.google.com/store/apps/details?id=com.onemainstream.dc.android&hl=en">
                    <img style={{ border: '0px', marginTop: '3px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png" alt="Google Play Store button" width={'130px'} height={'35px'} border="0" />
                  </a>
                </td>
                <td style={tdStyle}>
                  <a style={linkStyle} href="https://play.google.com/store/apps/details?id=com.onemainstream.dc.android&hl=en">
                    <img style={{ border: '0px', marginTop: '3px' }} src="https://gallery.mailchimp.com/b88b3ba75f26335343bfecdc0/images/5e301e76-08a9-459e-ad1a-b9eab2f28c0c.png" alt="Google Play Store button" width={'130px'} height={'50px'} border="0" />
                  </a>
                </td>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-area">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 text-center text-lg-left">
              <div class="copyright-text">
                <p>Copyright &copy; 2025, All Right Reserved <a href="https://codepen.io/anupkumar92/">PC Computer</a></p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              <div class="footer-menu">
                <ul>
                  {/* <li><a href="/">Home</a></li> */}
                  {/* <li><a href="/termsofservice">Terms of services</a></li> */}
                  <li><a href="/privacypolicy">Privacy policy</a></li>
                  <li><a href="/returnpolicy">policy</a></li>
                  {/* <li><a href="#">Contact us</a></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
